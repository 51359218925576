
          @import "src/css/_variables.scss";
        
.articlePrice {
  .price {
    margin-top: 1rem;
    font-size: 1rem;
  }
  .quantityAddToCart {
    margin-top: 1rem;
    //display: flex;
    div {
      //display: inline-block;
     
      select {
        //padding-right: 40px;
        cursor: pointer;
      }
    }
  }
  .priceRow{
    &.discountPrice {
      color: #d9534f;
      font-weight: bold;
    }

    &.regularPrice {
      font-size: 0.91rem;
      text-decoration: line-through;
      color: grey;
      margin-left: .5rem !important;
    }
  }
}